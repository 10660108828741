<template>
  <div class="evaluate"
       style="width:100%;height:100%;overflow: auto;">
    <van-nav-bar :title="$t('viewEvaluate.EvaTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <van-field class="gzxz"
               :label="$t('viewEvaluate.EvaTxt2')"
               required
               :placeholder="$t('module.PleaseChoose')"
               :value="result"
               @click="showResult = true"
               right-icon="arrow"
               readonly>
    </van-field>
    <!-- 面试结果 -->
    <img v-show="!!qresult"
         style="display:none;position: absolute;z-index: 999999;top: 65px; right: 20px;width:75px;"
         src="@/assets/img/toExamined-end.png"
         alt="" />
    <van-popup v-if="!qresult"
               v-model="showResult"
               position="bottom">
      <van-picker :title="$t('module.PleaseChoose')"
                  show-toolbar
                  :columns="resultColumns"
                  @confirm="onResultConfirm"
                  @cancel="showResult == false" />
    </van-popup>
    <van-field v-if="result == '面试淘汰'"
               class="gzxz"
               label="淘汰原因"
               required
               :placeholder="$t('module.PleaseChoose')"
               :value="iv_outreason"
               @click="showReason = true"
               right-icon="arrow"
               readonly>
    </van-field>
    <van-popup v-if="!qresult"
               v-model="showReason"
               position="bottom">
      <van-picker :title="$t('module.PleaseChoose')"
                  show-toolbar
                  :columns="ReasonColumns"
                  @confirm="onReasonConfirm"
                  @cancel="showReason == false" />
    </van-popup>
    <div class="pjmxl"
         v-if="pjdata.length > 0">
      <div class="pjmxl_top">
        <div class="pjmxl_top_left">
          <span>{{$t('viewEvaluate.EvaTxt3')}}</span>
        </div>
        <div class="pjmxl_top_right"><span>{{$t('viewEvaluate.EvaTxt4')}}</span></div>
      </div>
      <div class="pjmxl_body">
        <div class="pjmxl_top_list"
             v-for="(value,key) in pjdata"
             :key="key">
          <div class="fzclick"
               v-if="!!qresult"></div>
          <div class="flexboxbox">
            <div class="pjmxl_top_listLeft"
                 :class="((result == '面试通过' && value.tg_bt) || (result == '面试淘汰' && value.tt_bt) || (result == '待定' && value.dd_bt))?'pjmxl_top_must':''">
              {{ value.optitle }}
            </div>
            <div v-if="value.opflagname == '单选' || value.opflagname == '多选'"
                 class="pjmxl_top_listRight">
              <label :for="'id_a_'+value.autoid+'_k_'+idx"
                     v-for="(itm,idx) in value.optarr"
                     :key="idx">
                <input :readonly="!!qresult"
                       type="radio"
                       :id="'id_a_'+value.autoid+'_k_'+idx"
                       v-if="value.opflagname == '单选'"
                       v-model="value.select"
                       :value="itm"
                       @change="changeRadio(value)">
                <input :readonly="!!qresult"
                       type="checkbox"
                       :id="'id_a_'+value.autoid+'_k_'+idx"
                       v-model="value.select"
                       :value="itm"
                       v-else-if="value.opflagname == '多选'">
                <span>{{ itm }}</span>
              </label>
            </div>
            <div v-else-if="value.opflagname == '文本'"
                 class="pjmxl_top_listRight">
              <input :readonly="!!qresult"
                     type="text"
                     class="listRight_text"
                     v-model="value.select">
            </div>
            <div v-else-if="value.opflagname == '日期'"
                 class="pjmxl_top_listRight">
              <van-field v-if="!!qresult"
                         readonly
                         name="datePicker"
                         :value="value.select" />
              <van-field v-else
                         readonly
                         clickable
                         name="datePicker"
                         :value="formatDate(value.select,'yyyy-MM-dd')"
                         placeholder="点击选择时间"
                         @click="showDatePicker = true" />
              <van-popup v-model="showDatePicker"
                         position="bottom">
                <van-datetime-picker type="date"
                                     v-model="value.select"
                                     :min-date="minDate"
                                     :max-date="maxDate"
                                     :formatter="formatter"
                                     @confirm="onDateConfirm"
                                     @cancel="showDatePicker = false" />
              </van-popup>
            </div>
            <div v-else-if="value.opflagname == '日期时间'"
                 class="pjmxl_top_listRight">
              <van-field v-if="!!qresult"
                         readonly
                         name="datePicker"
                         :value="value.select" />
              <van-field v-else
                         readonly
                         clickable
                         name="datetimePicker"
                         :value="formatDate(value.select)"
                         placeholder="点击选择时间"
                         @click="showDateTimePicker = true" />
              <van-popup v-model="showDateTimePicker"
                         position="bottom">
                <van-datetime-picker type="datetime"
                                     v-model="value.select"
                                     :min-date="minDate"
                                     :max-date="maxDate"
                                     :formatter="formatter"
                                     @confirm="onDateTimeConfirm"
                                     @cancel="showDateTimePicker = false" />
              </van-popup>
            </div>
            <div v-else-if="value.opflagname == '整数'"
                 class="pjmxl_top_listRight">
              <van-stepper v-model="value.select"
                           :disabled="!!qresult"
                           min="1"
                           :integer="true"
                           step="1"
                           style="width:100%" />
              <!-- <input :readonly="!!qresult"
                     type="text"
                     class="listRight_text"
                     v-model="value.select"> -->
            </div>
            <div v-else-if="value.opflagname == '浮点数'"
                 class="pjmxl_top_listRight">
              <input :readonly="!!qresult"
                     type="number"
                     class="listRight_text"
                     v-model="value.select"
                     @blur="blurIpt(key)">
            </div>
            <div v-else-if="value.opflagname == '下拉列表'"
                 class="pjmxl_top_listRight">
              <van-field readonly
                         :disabled="!!qresult"
                         clickable
                         name="picker"
                         :value="value.select"
                         placeholder="请选择"
                         @click="getPickerData(value,key)" />
            </div>
          </div>
          <div v-if="value.opflagname == '单选'"
               style="position: absolute;
                      right: -15px;
                      top: 0px;
                      color: red;
                      font-size: 16px;
                      height: 100%;
                      display: flex;
                      align-items: center;">{{value.score}}</div>
        </div>
      </div>
      <div style="text-align: left; font-size: 16px; color: red; margin-top: 10px;">评价总得分：{{totalScore}}</div>
    </div>
    <van-cell class="mspj"
              :class="result == '面试通过'?'requiredPj':''"
              :value="$t('viewEvaluate.EvaTxt1')" />
    <van-field v-model="iv_pj"
               rows="5"
               class="remark"
               autosize
               type="textarea"
               :placeholder="$t('module.PleaseEnter')"
               show-word-limit
               :disabled="!!qresult" />
    <van-cell :value="$t('module.remark')" />
    <van-field v-model="remark"
               rows="5"
               class="remark"
               autosize
               type="textarea"
               :placeholder="$t('module.PleaseEnter')"
               show-word-limit
               :disabled="!!qresult" />
    <div class="footer"
         v-if="!qresult">
      <van-button type="info"
                  @click="saveIvSetView"
                  :disabled="isDisable">{{$t('module.confirm')}}</van-button>
    </div>

    <!-- 普通下拉选的弹窗 -->
    <van-popup v-model="showPicker"
               position="bottom"
               round>
      <van-picker show-toolbar
                  :columns="columns"
                  @confirm="onPickerConfirm"
                  @cancel="showPicker = false">
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import { ivSetView, getPjDetail } from '@api/wxzp.js';
import { GetFilterDropDownList } from '@api/wx.js';
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { Toast } from 'vant';
export default {
  data () {
    return {
      proposal: "", // 建议
      popType: "",
      showWorkType: false,
      showResult: false,
      resultColumns: [],
      result: this.$route.query.iv_res, // 面试结果
      qresult: this.$route.query.iv_res, // 面试结果
      remark: this.$route.query.remark,
      iv_pj: this.$route.query.iv_pj,
      userInfo,
      leftToPath: "",
      isDisable: false,
      pjdata: [],
      radio: 1,
      showDatePicker: false,
      showDateTimePicker: false,
      minDate: new Date(),
      maxDate: new Date(2121, 1, 1),
      showPicker: false, // 下拉选弹窗显示
      columns: [], // 下拉选选项组
      pickerIdx: "", // 数组下标
      iv_outreason: this.$route.query.iv_outreason, // 淘汰原因
      showReason: false, // 淘汰原因下拉
      ReasonColumns: [],
      totalScore: 0
    };
  },
  created () {
    this.getFilrData();
    this.getResonFilrData()
    if (this.$route.query.pjid)
    {
      this.getPjContent()
    }
  },
  methods: {
    changeRadio (val) {
      this.totalScore = 0
      if (this.pjdata.length > 0)
      {
        this.pjdata.forEach((ele, ix) => {
          if (ele.opflagname == '单选')
          {
            let ops = ele.optarr
            if (ops.length > 0)
            {
              ops.forEach((it, idx) => {
                if (ele.select == it)
                {
                  this.totalScore += parseInt(ele.scorearr[idx])
                }
              })
            }
          }
          if (val.autoid == ele.autoid)
          {
            let ops = ele.optarr
            if (ops.length > 0)
            {
              ops.forEach((it, idx) => {
                if (ele.select == it)
                {
                  val.score = parseInt(ele.scorearr[idx])
                  // this.$forceUpdate()
                }
              })
            }
          }
        });
      }
    },
    // 下拉选择器
    getPickerData (val, key) {
      this.pickerIdx = key
      this.columns = val.optarr
      this.showPicker = true
    },
    // 下拉选择选择回调
    onPickerConfirm (value) {
      this.pjdata[this.pickerIdx].select = value
      this.showPicker = false
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear();
        const m = time.getMonth() + 1;
        const d = time.getDate();
        const h = time.getHours();
        const mm = time.getMinutes();
        const s = time.getSeconds();
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d) + ' ' + this.add0(h) + ':' + this.add0(mm) + ':' + this.add0(s)
        }
      } else
      {
        return ""
      }
    },
    formatter (type, val) {
      if (type === 'year')
      {
        return val + '年';
      }
      if (type === 'month')
      {
        return val + '月';
      }
      if (type === 'day')
      {
        return val + '日';
      }
      return val;
    },
    // 浮点数输入框校验
    blurIpt (index) {
      const val = this.pjdata[index]['select']
      let reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
      if (val)
      {
        if (isNaN(val) || (!reg.test(val)))
        {
          Toast('请输入数字值，只允许保留两位小数')
          this.pjdata[index]['select'] = ''
        }
      }
    },
    // 日期选择回调
    onDateConfirm (value) {
      this.showDatePicker = false;
    },
    // 日期时间选择回调
    onDateTimeConfirm (value) {
      this.showDateTimePicker = false;
    },
    getFilrData () {
      GetFilterDropDownList({
        moduleno: 201,
        username: this.userInfo.username,
        listsql: "@面试结果"
      }).then(res => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.resultColumns.push(res.data[i].dicvalue)
          }
        }
        // console.log(res)
      })
    },
    getResonFilrData () {
      GetFilterDropDownList({
        moduleno: 201,
        username: this.userInfo.username,
        listsql: "@面试淘汰原因"
      }).then(res => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.ReasonColumns.push(res.data[i].dicvalue)
          }
        }
        // console.log(res)
      })
    },
    getPjContent () {
      getPjDetail({
        pjid: this.$route.query.pjid
      }).then(res => {
        // console.log(res,'resresres');
        let pjarr = res.data;
        for (let i = 0; i < pjarr.length; i++)
        {
          pjarr[i].optarr = pjarr[i].oplist.split(",");
          if (pjarr[i].opflagname != "多选")
          {
            if (pjarr[i].opres)
            {
              pjarr[i].select = pjarr[i].opres;
            } else
            {
              pjarr[i].select = "";
            }
          } else
          {
            if (pjarr[i].opres)
            {
              pjarr[i].select = pjarr[i].opres.split("|");
            } else
            {
              pjarr[i].select = [];
            }
          }
          if (pjarr[i].scorelist)
          {
            pjarr[i].scorearr = pjarr[i].scorelist.split(",")
          }
          if (pjarr[i].opflagname == "单选")
          {
            if (!pjarr[i].score)
            {
              pjarr[i].score = 0
            }
            this.totalScore += pjarr[i].score
          }
        }
        // console.log(pjarr);
        this.pjdata = pjarr;
        console.log(this.pjdata, 'this.pjdata真的来了吗？');
      })
    },
    onClickLeft () {
      if (this.$route.query.returnPath == "viewInfo")
      {
        this.$router.push({
          path: "/" + this.$route.query.returnPath,
          query: {
            autoid: this.$route.query.autoid,
            footerType: "待入职"
          }
        });
      } else
      {
        this.$router.push({
          path: "/" + this.$route.query.returnPath
        });
      }
    },
    showPopup (name) {
      this.showWorkType = true;
      this.popType = name;
    },
    onResultConfirm (value) {
      this.result = value;
      this.showResult = false;
    },
    showResultPopup () {
      this.showResult = true;
    },
    onResultCancel () {
      this.showResult = false;
    },
    onConfirm (value, index) {
      this.showWorkType = false;
      if (this.popType == "评分")
      {
        this.score = value;
      } else if (this.popType == "建议")
      {
        this.proposal = value;
      }
    },
    onCancel () {
      this.showWorkType = false;
    },
    saveIvSetView () {
      if (!this.result)
      {
        Toast(this.$t('viewEvaluate.EvaTxt5'));
        return false;
      }
      if (this.result == '面试通过' && !this.iv_pj)
      {
        Toast(this.$t('viewEvaluate.EvaTxt6'));
        return false;
      }
      if (this.result == '面试淘汰' && !this.iv_outreason)
      {
        Toast('淘汰原因不能为空！');
        return false;
      }
      let pjdetail = "";
      if (this.pjdata.length > 0)
      {
        for (let i = 0; i < this.pjdata.length; i++)
        {
          if (this.pjdata[i].opflagname != "多选")
          {
            // if (this.pjdata[i].select == "" && this.result != '面试淘汰')
            if (this.pjdata[i].select == "" && ((this.result == '面试通过' && this.pjdata[i].tg_bt) || (this.result == '面试淘汰' && this.pjdata[i].tt_bt) || (this.result == '待定' && this.pjdata[i].dd_bt)))
            {
              Toast(this.$t('viewEvaluate.EvaTxt7') + '--' + this.pjdata[i].optitle);
              return false;
            } else
            {
              if (this.pjdata[i].opflagname == "日期")
              {
                this.pjdata[i].select = this.formatDate(this.pjdata[i].select, 'yyyy-MM-dd')
              } else if (this.pjdata[i].opflagname == "日期时间")
              {
                this.pjdata[i].select = this.formatDate(this.pjdata[i].select)
              }
              if (i == this.pjdata.length - 1)
              {
                if (this.pjdata[i].opflagname == "单选")
                {
                  pjdetail += this.pjdata[i].autoid + ":" + this.pjdata[i].select + '{' + this.pjdata[i].score + '}'
                } else
                {
                  pjdetail += this.pjdata[i].autoid + ":" + this.pjdata[i].select
                }
              } else
              {
                if (this.pjdata[i].opflagname == "单选")
                {
                  pjdetail += this.pjdata[i].autoid + ":" + this.pjdata[i].select + '{' + this.pjdata[i].score + '}' + ","
                } else
                {
                  pjdetail += this.pjdata[i].autoid + ":" + this.pjdata[i].select + ","
                }
              }
            }
          } else
          {
            // if (this.pjdata[i].select.length == 0 && this.result != '面试淘汰')
            if (this.pjdata[i].select.length == 0 && ((this.result == '面试通过' && this.pjdata[i].tg_bt) || (this.result == '面试淘汰' && this.pjdata[i].tt_bt) || (this.result == '待定' && this.pjdata[i].dd_bt)))
            {
              Toast(this.$t('viewEvaluate.EvaTxt7') + '--' + this.pjdata[i].optitle);
              return false;
            } else
            {
              if (i == this.pjdata.length - 1)
              {
                pjdetail += this.pjdata[i].autoid + ":" + this.pjdata[i].select.join("|")
              } else
              {
                pjdetail += this.pjdata[i].autoid + ":" + this.pjdata[i].select.join("|") + ","
              }
            }
          }
        }
      }
      // console.log(pjdetail)
      ivSetView({
        autoid: this.$route.query.autoid,
        iv_res: this.result,
        iv_pj: this.iv_pj,
        iv_outreason: this.iv_outreason,
        remark: this.remark,
        username: this.userInfo.username,
        pjdetail
      }).then(res => {
        if (res.data[0].info == '')
        {
          Toast(this.$t('viewEvaluate.EvaTxt8'));
          this.isDisable = true;
          this.$router.push("/toInterview");
        } else
        {
          Toast(res.data[0].info);
        }
      })
    },
    onReasonConfirm (value) {
      this.iv_outreason = value;
      this.showReason = false;
    },
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  // height: 100vh;
  text-align: center;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    ::v-deep .van-nav-bar__right {
      span {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    .van-icon-ellipsis {
      color: #fff;
    }
  }
  .van-field {
    margin-bottom: 28px;
    box-sizing: border-box;
  }
  /deep/.van-cell__value {
    .van-field__body {
      input {
        text-align: right;
      }
    }
  }
  .mspj {
    padding-left: 18px;
    box-sizing: border-box;
  }
  .requiredPj {
    span::before {
      content: "*";
      color: red;
      margin-right: 4px;
    }
  }
  /deep/.gzxz::after {
    border-bottom: 0 !important;
  }
  .footer {
    padding: 30px 0;
    background: #fff;
    .remark {
      margin-bottom: 0;
      height: calc(100% - 180px);
      box-sizing: border-box;
    }
    .van-button {
      width: 90%;
    }
  }
  .pjmxl {
    width: 100%;
    background: #fff;
    // padding: 0.26667rem 0.42667rem;
    padding: 20px 50px 20px 40px;
    margin-bottom: 0.37333rem;
    .pjmxl_top {
      width: 100%;
      display: flex;
      height: 80px;
      align-items: center;
      border-top: 1px solid #666;
      border-left: 1px solid #666;
      border-right: 1px solid #666;
      border-bottom: 1px solid #666;
      .pjmxl_top_left {
        width: 30%;
        font-size: 28px;
        height: 100%;
        border-right: 1px solid #666;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
      .pjmxl_top_right {
        width: 70%;
        height: 100%;
        font-size: 28px;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
    }
    .pjmxl_body {
      width: 100%;
      .fzclick {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 999999;
        top: 0;
      }
      .pjmxl_top_list {
        width: 100%;

        height: 80px;
        position: relative;
        align-items: center;
        border-left: 1px solid #666;
        border-right: 1px solid #666;
        border-bottom: 1px solid #666;
        input:disabled {
          background: blue;
        }
      }
      .flexboxbox {
        display: flex;
        width: 100%;
        height: 100%;
      }
      .pjmxl_top_listLeft {
        width: 30%;
        font-size: 24px;
        height: 100%;
        border-right: 1px solid #666;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
      }
      .pjmxl_top_must:before {
        content: "*";
        color: red;
        margin-right: 4px;
        // position: absolute;
        // left: 0.21333rem;
        // color: #ee0a24;
        // font-size: 0.37333rem;
        // content: "*";
      }
      .pjmxl_top_listRight {
        width: 70%;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        label {
          font-size: 24px;
          display: flex;
          align-items: center;
          margin: 0 6px;
        }
        .listRight_text {
          font-size: 24px;
          height: 95%;
          width: 98%;
        }
        .van-cell {
          margin-bottom: 0;
          padding: 0;
          font-size: 24px;
          height: 95%;
          width: 98%;
          border: 1px solid #666;
          /deep/.van-cell__value {
            .van-field__body {
              height: 100%;
              .van-field__control {
                text-align: left;
              }
            }
          }
        }
        /deep/.van-stepper {
          .van-stepper__input {
            width: 60%;
          }
        }
      }
    }
  }
}
</style>
